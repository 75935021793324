<div class="card-cont">

    <div [ngStyle]="{ 'background-image': 'url(' + srcBanner + ')'}" class="card-body homepage" *ngIf="!isSuccess">
        <input type="text" inputmode="numeric" class="phone" matInput placeholder="Vui lòng nhập số điện thoại"
            name="phone" [(ngModel)]="phone" (input)="getInfoByPhone()">
        <input matInput placeholder="Vui lòng nhập họ tên (nơi làm việc)" name="note" [(ngModel)]="request.note"
            *ngIf="isNotNote" style="margin-top: 5px;">
        <div *ngIf="isLoading" style="margin-top: 10px; text-align: center;">
            <p style="color: rgb(244, 246, 250);font-size: 17px;">Họ tên:
                {{request.note}}</p>
            <p style="color: rgb(233, 225, 190);font-size: 17px;">Khoa phòng:
                {{infoEmployee.department}}</p>
        </div>
        <div style="text-align: center;">
            <button type="button" class="btn btn-danger" style="margin-top: 10px;" (click)="onAdd()"
                *ngIf="request.note !== ''">
                Check-in</button>
        </div> <br>
        <mat-error style="margin-top: 10px;">{{textError}}</mat-error>
    </div>

    <div *ngIf="isSuccess && id === uomMamHP" [ngStyle]="{ 'background-image': 'url(' + srcBanner + ')'}"
        class="homepage" style="text-align: center;">

        <p style="font-weight: 600;font-size: 20px;color:#606161;;padding-top: 40vh;"> <i class="fa fa-check"
                aria-hidden="true" style="color: #606161;opacity: 0.98;"></i> CHECK-IN THÀNH CÔNG</p>
        <p style="color: #606161;font-size: 17px;">Họ tên: {{request.note}}</p>
        <p style="color: #606161;font-size: 17px;" *ngIf="infoEmployee.department">
            Khoa phòng: {{infoEmployee.department}}
        </p>
        <div style="margin-top: 30px;">
            <span style="color:#606161;font-size: 16px;">
                Chào mừng bạn đến với đêm nhạc hội "MÃI BÊN NHAU BẠN NHÉ!",
                mừng kỷ niệm 10 năm thành lập bệnh viện Mỹ Đức và 05 năm bệnh viện Mỹ Đức Phú Nhuận</span><br>
            <!-- <span style="color: rgb(228, 70, 42);font-size: 16px;">{{nameHoiNghi}}</span> -->
            <span style="color:#606161;font-size: 16px;">10 năm một hành trình</span>
        </div>
        <button type="button" class="btn btn-primary" style="margin-top: 100px;" (click)="returnPage()">Quay
            lại</button>
    </div>

    <div *ngIf="isSuccess" [ngStyle]="{ 'background-image': 'url(' + srcBanner + ')'}" class="homepage"
        style="text-align: center;">
        <p style="font-weight: 600;font-size: 20px;color: #f0ecec;padding-top: 40vh;"> <i class="fa fa-check"
                aria-hidden="true" style="color: green;"></i> Check-in thành công</p>
        <p style="color: rgb(244, 246, 250);font-size: 17px;">Họ tên: {{request.note}}</p>
        <p style="color: rgb(233, 225, 190);font-size: 17px;" *ngIf="infoEmployee.department">Khoa phòng:
            {{infoEmployee.department}}</p>
        <div style="margin-top: 30px;">
            <span style="color: rgb(228, 70, 42);font-size: 16px;">Chào mừng Quý đồng nghiệp đến với</span><br>
            <span style="color: rgb(228, 70, 42);font-size: 16px;">{{eventName}}</span>
        </div>
        <button type="button" class="btn btn-primary" style="margin-top: 100px;" (click)="returnPage()">Quay
            lại</button>
    </div>

    <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
        <p class="loading">Đang load thông tin...</p>
    </ngx-spinner>
</div>