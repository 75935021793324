import { forkJoin, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { tap, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
const routes = 'common/';

@Injectable({
    providedIn: 'root'
})
export class EventHospitalService {

    constructor(private apiService: ApiService, private httpClient: HttpClient) { }

    getInfor(phone) {
        return this.apiService.get(routes + 'employee_ext/' + phone).pipe(map(res => {
            return res;
        }));
    }
    getBanner(guid) {
        return this.apiService.get(routes + 'conferences_banner/' + guid).pipe(map(res => {
            return res;
        }));
    }
    getName(guid) {
        return this.apiService.get(routes + 'conferences_name/' + guid).pipe(map(res => {
            return res;
        }));
    }

    getAllConferences(guid) {
        const urlBanners = this.apiService.get(routes + 'conferences_banner/' + guid);
        const urlName = this.apiService.get(routes + 'conferences_name/' + guid);
        return forkJoin({
            conferencesBanners: urlBanners,
            conferencesName: urlName
        }).pipe(map(data => data = {
            conferencesBanners: data.conferencesBanners,
            conferencesName: data.conferencesName
        }
        ));
    }

    postInfor(guid, request) {
        return this.apiService.post(routes + 'conferences/' + guid + '/checkin', request)
            .pipe(map(res => {
                return res;
            }));
    }
}