import { Component, OnInit, } from "@angular/core";
import { EventHospitalService } from "../service/EventHospitalService";
import { ActivatedRoute } from "@angular/router";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material";
import { AppDateAdapter, APP_DATE_FORMATS } from "./date.adapter";
import { NgxSpinnerService } from "ngx-spinner";
import { EnumEvent } from "./event-hospital";

@Component({
  selector: "app-event-hospital",
  templateUrl: "./event-hospital.component.html",
  styleUrls: ["./event-hospital.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
  ],
})
export class EventHospitalComponent implements OnInit {
  public id = "";
  public srcBanner = "";
  public eventName = "";
  public phone = "";
  public infoEmployee = { name: "", department: "", phone: "", id: 0 };
  public request = { employee_id: 0, phone: "", note: "" };
  public isSuccess = false;
  public isNotNote = false;
  public isLoading = false;
  public textError = "";
  public uomMamHP = EnumEvent.UomMamHP;

  constructor(
    private EventHospitalService: EventHospitalService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) { }



  ngOnInit() {
    this.id = this.route.snapshot.params.id;
    this.getInfoCommon();
  }

  getInfoCommon() {
    this.EventHospitalService.getAllConferences(this.id).subscribe(response => {
      console.log(response)
      this.srcBanner = response.conferencesBanners.data;
      this.eventName = response.conferencesName.data;
    });
  }

  getInfoByPhone() {
    this.spinner.show();
    this.isLoading = false;
    if (this.phone.length < 10) {
      this.spinner.hide();
      this.textError = "Số điện thoại không đúng. Vui lòng nhập lại!";
      setTimeout(() => {
        this.textError = "";
      }, 3000);
    } else {
      this.EventHospitalService.getInfor(this.phone).subscribe(
        (rs) => {
          if (rs == null || rs.data == null) {
            this.infoEmployee = { name: "", department: "", phone: "", id: 0 };
            this.spinner.hide();
            this.isSuccess = false;
            this.isNotNote = true;
            return 0;
          }
          this.infoEmployee = rs.data;
          this.request.note = this.infoEmployee.name;
          this.request.employee_id = this.infoEmployee.id;
          this.isLoading = true;
          this.spinner.hide();
        },
        (error) => {
          this.isSuccess = false;
          this.isNotNote = true;
          this.infoEmployee = { name: "", department: "", phone: "", id: 0 };
          this.spinner.hide();
        }
      );
    }
  }

  onAdd() {
    this.spinner.show();
    this.request.phone = this.phone;
    this.EventHospitalService.postInfor(this.id, this.request).subscribe(
      (rs) => {
        if (rs.status_code != 200) {
          this.isSuccess = false;
          this.isNotNote = false;
          this.spinner.hide();
          return;
        }
        this.isSuccess = true;
        this.isNotNote = false;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.isSuccess = false;
        this.textError = "Số điện thoại đã Check-in hoặc lỗi hệ thống!";
      }
    );
  }

  returnPage() {
    this.phone = "";
    this.textError = "";
    this.infoEmployee = { name: "", department: "", phone: "", id: 0 };
    this.request = {
      employee_id: 0,
      phone: "",
      note: "",
    };
    this.isSuccess = false;
    this.isNotNote = false;
    this.isLoading = false;
  }
}
