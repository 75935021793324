import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
// import { WarningComponent } from 'src/app/shared/components/warning/warning.component';
// import { Message } from '/message';

const BASE_URL = 'https://patient.myduchospital.vn:4433/tinasoft/hyvong/';
// const BASE_URL = 'http://172.16.1.35:9999/tinasoft/hyvong/';
// const BASE_URL = 'http://172.68.2.136/server/hyvong/';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Accept-Language': 'vi',
      // 'Access-Control-Allow-Origin': '*'
      // Tenant: localStorage.getItem('tenantId') == null ? '' : localStorage.getItem('tenantId')
    })
  };

  constructor(
    private httpClient: HttpClient,
    public dialog: MatDialog,
    // private message: Message,
  ) {
  }

  public get(path: string, params: HttpParams = new HttpParams()
  ): Observable<any> {
    this.options.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': 'vi',
      // 'Access-Control-Allow-Origin': '*'
      // Tenant: localStorage.getItem('tenantId') == null ? '' : localStorage.getItem('tenantId')
    });
    return this.httpClient
      .get(BASE_URL + path, { ...this.options, params })
      .pipe(catchError(this.formatErrors));
  }

  public post(path: string, body: object = {}): Observable<any> {
    this.options.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': 'vi',
      // Tenant: localStorage.getItem('tenantId') == null ? '' : localStorage.getItem('tenantId')
    });
    return this.httpClient
      .post(BASE_URL + path, JSON.stringify(body), this.options)
      .pipe(catchError(this.formatErrors));
  }

  public formatErrors(error: any): Observable<any> {
    console.log(error);
    return throwError(error.error);
  }

  /**
   * Get base url of web api.
   */
  public getBaseUrl() {
    return BASE_URL;
  }
}
